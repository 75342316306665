import UIkit from '../vendor/uikit-3.2.1/js/uikit.js';
import '../vendor/uikit-3.2.1/js/uikit-icons.js';


(function ($) {

	$(function () {
		// handle the ajax boking form
		let $form = $('form');
		$form.find('input, textarea, select').on('keyup change', function () {
			$(this).removeClass('uk-form-danger')
		});
		$form.submit(function (e) {
			e.preventDefault();
			$form.find('input, textarea, select').removeClass('uk-form-danger');
			$form.find('.error').hide();
			$.post('/email.php', $(this).serialize(), function (response) {
				if (response.success) {
					$form.find('.form-controls').hide();
					$form.find('.success').show();
					$form.find('.warning').hide();
				} else if (response.recaptcha) {
					console.log(response.recaptcha)
					$form.find('.g-recaptcha').css('border', '1px solid red');
					$form.find('.warning').show();
				} else if (response.error) {
					console.log(response.error)
					$form.find('.error').show();
				} else {
					$form.find('.warning').show();
					for (var key in response) {
						if (response.hasOwnProperty(key)) {
							$form.find('[name="' + key + '"]').addClass('uk-form-danger');
						}
					}
				}
			});
		});
	});


}(jQuery))